.react-datepicker-popper {
  margin-top: 0 !important;

  left: 0px !important;
  top: 0px !important;
  z-index: 20;

  transform: translate3d(13px, 131px, 0px) !important;
}

.default-deatepicker-calendar-classname {
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 2.4rem 6rem rgba(33, 9, 9, 0.16);
  background-color: #fdfdfd;
  display: flex;
  margin-top: 1rem;
}

.InputClassName {
  color: red;
}

.react-datepicker__month-container {
  padding: 1.8rem 1rem 2.2rem 1rem;
  // border-right: 1px solid rgba(43, 83, 216, 0.08);
  box-shadow: 0px 0px 10px rgba(107, 201, 219, 0.25);
  border-radius: 8px;
}

.react-datepicker__header {
  background-color: #fdfdfd;
  border-bottom: none;
  padding: 0;
}

.react-datepicker__current-month {
  color: #222222;
  font-size: 1.4rem;
  font-family: "SF Pro Text Regular";
  font-weight: 500;
  margin: 0;
  margin-bottom: 3.4rem;

  @media only screen and (max-width: 864px) {
    margin-bottom: 2.4rem;
  }
}

.react-datepicker__day-name {
  width: 4rem;
  color: #5a5a5a;
  font-size: 1.2rem;
  font-family: "SF Pro Text Regular";
  margin: 0;
  margin-bottom: 0.8rem;

  @media only screen and (max-width: 864px) {
    width: 3rem;
    margin-bottom: 0.3rem;
  }
}

.DayClassName {
  width: 4rem;
  height: 4rem;
  outline: none;
  border-radius: 50%;
  font-size: 1.4rem;
  font-family: "SF Pro Text Regular";
  color: #4c5573;
  margin: 0;
  line-height: 4rem;

  @media only screen and (max-width: 864px) {
    width: 3.2rem;
    height: 3.6rem;
    line-height: 3.5rem;
  }

  &:hover {
    background-color: rgba(34, 95, 188, 0.08);
    border-radius: 50%;
  }
}

.react-datepicker__day--selected {
  color: #fdfdfd;
  background-color: #CC00A0;
 
  &:hover {
    color: #fdfdfd;
    background-color: #CC00A0;
  }
}

.react-datepicker__day--disabled {
  opacity: 0.3;
}

.react-datepicker__navigation--previous {
  background: url("../../../environment/theme/icons/EpArrowLeft.svg") no-repeat;
  width: 3rem;
  height: 3rem;
  border: none;
  background-size: contain;
}

.react-datepicker__navigation--next {
  background: url("../../../environment/theme/icons/EpArrowRight.svg") no-repeat;
  width: 3rem;
  height: 3rem;
  border: none;
  background-size: contain;
}
