* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  ::-webkit-scrollbar {
    width: 0.4rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(10, 14, 18, 0.2);
    border-radius: 1rem;
  }
}

html {
  font-size: 62.5%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: 100vh;
  overflow-y: hidden;
  background-color: #f2f3f5;
}

input,
textarea {
  font-family: "SF Pro Text Regular", "Open Sans", -apple-system, sans-serif;
  font-size: 1.6rem;
}

p {
  font-family: "SF Pro Display Regular", "Open Sans", -apple-system, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
}

h1 {
  font-family: "SF Pro Display Bold", "Open Sans", -apple-system, sans-serif;
  font-size: 6.4rem;
}

h4 {
  font-family: "SF Pro Display Regular", "Open Sans", -apple-system, sans-serif;
  font-size: 2.4rem;
}

h5 {
  font-family: "SF Pro Display Regular", "Open Sans", -apple-system, sans-serif;
  font-size: 1.8rem;
}

h6 {
  font-family: "SF Pro Display Regular", "Open Sans", -apple-system, sans-serif;
  font-size: 1.4rem;
}

@font-face {
  font-family: "SF Pro Display Bold";
  src: url("/fonts/SFProDisplay-Bold.woff2");
}

@font-face {
  font-family: "SF Pro Display Regular";
  src: url("/fonts/SFProDisplay-Regular.woff2");
  font-weight: normal;
}

@font-face {
  font-family: "SF Pro Text Bold";
  src: url("/fonts/SFProText-Bold.woff2");
}

@font-face {
  font-family: "SF Pro Text Regular";
  src: url("/fonts/SFProText-Regular.woff2");
  font-weight: normal;
}

@font-face {
  font-family: "Kanit Light";
  src: url("/fonts/Kanit-Light.otf");
}

@font-face {
  font-family: "Kanit Regular";
  src: url("/fonts/Kanit-Regular.otf");
}

@font-face {
  font-family: "Kanit Bold";
  src: url("/fonts/Kanit-Bold.otf");
}

@font-face {
  font-family: "Concert One";
  src: url("/fonts/ConcertOne-Regular.otf");
}

@font-face {
  font-family: "Open Sans";
  src: url("/fonts/open-sans.otf");
}

@font-face {
  font-family: "Open Sans Semibold";
  src: url("/fonts/SourceSansPro-SemiBold.woff2");
}

.default-suspense-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
}

.tooltipCustom {
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 1rem;
  line-height: 20px;
  width: 320px;
}